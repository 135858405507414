<template>
  <div id="kid-family">
    <h3 class="narrow">Det mest värdefulla i era liv</h3>
    <hr/>
    <div id="first-paragraph">
      <div class="container">
        <h1>Babyfotografering</h1>
        <h2>0-1 år</h2>
        <p>Föreviga det du håller mest kärt! Ett och två så har de blivit tonåringar och spenderar mindre tid hemma med mamma och pappa, då kan ni plocka fram dessa bilder och minnas tillbaka.
        Minnas de uttrycksfulla minerna, de gulliga små men få tänderna och kanske till och med bebislukten smyger sig fram i minnet.</p>
        <p>Jag rekommenderar fotografering tidigast vid 3 mån då vi oftast kan få lite mer variation med att ligga på rygg, mage samt i korg. Eventuellt bjuds det på några leenden också! En annan perfekt fotoålder är när
        ert barn har lärt sig att sitta korta stunder utan stöd men inte lärt sig att krypa ännu. Alla åldrar är dock charmiga och välkomna!</p>
      </div>
    </div>
    <div id="carousel-holder">
      <div class="container no-padding">
        <ImageCarousel duration="3" :images="[
          './kid-family/carousel/1.jpg',
          './kid-family/carousel/2.jpg',
          './kid-family/carousel/3.jpg',
          './kid-family/carousel/4.jpg',
          './kid-family/carousel/5.jpg',
          './kid-family/carousel/6.jpg',
          './kid-family/carousel/7.jpg',
          './kid-family/carousel/8.jpg',
          './kid-family/carousel/9.jpg',
          './kid-family/carousel/10.jpg',
          './kid-family/carousel/11.jpg',
          './kid-family/carousel/12.jpg',
          './kid-family/carousel/13.jpg',
          './kid-family/carousel/14.jpg',
        ]"/>
      </div>
    </div>
    <div id="second-paragraph">
      <div class="container">
        <h1>Barn-/Familjefotografering</h1>
        <p>Självklart ska ni också passa på att fånga hela familjen på bild. Alla på en och samma bild, hur ofta händer det?</p>
        <p>I min familj är det alltid jag som fotar, med mobil eller kamera. Det betyder att jag nästan aldrig får vara med på bild tillsammans med mina barn.
          Hur tråkigt är inte det för dem att inte få se hur jag såg ut när de var små? För ditt/dina barns skull, se till att investera i en familjefotografering ni aldrig kommer att ångra!</p>
        <p>Är ert yngsta barn över 1 år så rekommenderar jag att kombinera med några familj- och/eller syskonbilder i samband med er barnfotografering. Jag rekommenderar även utomhus för familjer med två barn eller fler om yngsta är över 1 år
          då barnen uppskattar ett större område att kunna springa omkring på. I studion blir det mer begränsat.</p>
      </div>
    </div>
    <div id="image-section" class="container">
      <img src="@/assets/kid-family/first.jpg"/>
      <img src="@/assets/kid-family/second.jpg"/>
      <img src="@/assets/kid-family/third.jpg"/>
    </div>
    <Prices photography_price="995" :lines="['Mellan <strong>40-60 min</strong> fotograferingstid']"/>
    <ContactForm section="Familj"/>
  </div>
</template>

<script>
  import ImageCarousel from "@/components/ImageCarousel";
  import Prices from "@/components/Prices";
  import ContactForm from "@/components/ContactForm";

  export default {
    name: "KidFamily",
    components: {
      ImageCarousel,
      Prices,
      ContactForm
    },
    metaInfo() { return {
      title: 'Barn- & familj - Studio Petite',
      meta: [{
        name: 'description',
        content: ''
      }]
    }}
  }
</script>

<style scoped lang="stylus">

  div#kid-family

    h1
      margin: 0 auto

    h2
      font-size: 16px
      margin: 0 auto 30px

    div#first-paragraph
      padding: 20px 0

    div#carousel-holder
      max-width: 1200px
      margin: 0 auto
      padding: 20px 0

      div.container
        position: relative
        padding: 33.34% 0
        box-sizing: border-box

    div#second-paragraph
      background-color: #ece8e3
      padding: 20px 0

      h1
        margin: 0 auto 30px

    div#image-section
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
      gap: 20px
      padding: 20px 0

      img
        max-width: 100%
</style>