<template>
  <div id="video-container">
    <iframe
        :id="'id-' + id"
        :src="'https://www.youtube-nocookie.com/embed/' + id + '?controls=1&showinfo=0&modestbranding=1&rel=0&color=white&loop=1&playlist=' + id + (!isMobile ? '&mute=1' : '') + (visible ? '&autoplay=1' : '')"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    ></iframe>
  </div>
</template>

<script>
  import $ from "jquery"

  let visible = false;
  let isMobile = false;

  export default {
    name: "VideoPlayer",
    props: [
      "id"
    ],
    data() { return {
      visible: visible,
      isMobile: isMobile,
    }},
    mounted() {
      let that = this;
      $(window).on('load', function() {
        that.isMobile = $(window).width() < 600;

        if (!that.isMobile) {
          let element = $('#id-' + that.$props.id);
          $(window).on('scroll', function() {
            if (!that.visible && $(this).scrollTop() + $(this).height() > element.offset().top + element.height() / 4) {
              $(this).off('scroll');
              that.visible = true;
            }
          }).trigger('scroll');
        }
      })
    }
  }
</script>

<style scoped lang="stylus">

  div#video-container
    position: relative
    height: 0
    padding-bottom: 56.25%
    margin-bottom: 40px

    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      border: 0

</style>