var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"start-page"}},[_c('div',{attrs:{"id":"carousel-holder"}},[_c('ImageCarousel',{attrs:{"duration":"3","images":[
      './start/carousel/7.jpg',
      './start/carousel/4.jpg',
      './start/carousel/3.jpg',
      './start/carousel/2.jpg',
      './start/carousel/1.jpg',
      './start/carousel/5.jpg',
      './start/carousel/6.jpg' ]}})],1),_c('h1',[_vm._v("Barnfotograf och familjefotograf")]),_c('h2',[_vm._v("med fotostudio i Tyresö/Stockholm")]),_c('hr'),_vm._m(0),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h3',{staticClass:"narrow"},[_vm._v("Värdefulla stunder i ert liv")]),_c('p',[_vm._v("Välkommen till Studio Petite! Jag är en specialiserad barnfotograf som har inriktat mig främst på bebisar mellan 0-1 år men som också älskar att föreviga hela er familj eller graviditet.")]),_c('p',[_vm._v("Gillar ni jordnära, ljusa färger och inte allt för mycket krusiduller så har ni hittat rätt! Här kommer ni att få mysiga bilder med en lite varmare ton och en lite enklare stil som kommer att funka i många år framöver till alla möjliga inredningstrender och hem. Jag ser framemot att skapa fina minnen med er! ")]),_c('p',[_vm._v("Vill ni veta mer om mig bakom Studio Petite? "),_c('a',{attrs:{"href":"/info"}},[_vm._v("Klicka här")]),_vm._v(".")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"start-sections"}},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"section",attrs:{"href":"/pregnant"}},[_c('h2',[_vm._v("Gravid")]),_c('img',{attrs:{"src":require("@/assets/start/sections/pregnant.jpg")}})]),_c('a',{staticClass:"section",attrs:{"href":"/newborn"}},[_c('h2',[_vm._v("Nyfödd")]),_c('img',{attrs:{"src":require("@/assets/start/sections/newborn.jpg")}})]),_c('a',{staticClass:"section",attrs:{"href":"/kid"}},[_c('h2',[_vm._v("Barn")]),_c('img',{attrs:{"src":require("@/assets/start/sections/kid.jpg")}})]),_c('h3',[_vm._v("En investering för livet")])])])}]

export { render, staticRenderFns }