<template>
  <div id="cakesmash">
    <h3>En lekfull fotografering</h3>
    <hr/>
    <div id="first-paragraph">
      <div class="container">
        <h1>Cake smash</h1>
        <h2>- ettårsfotografering</h2>
        <p>Har ni en blivande ettåring där hemma så har ni kommit rätt!</p>
        <p>Jag lanserade denna fotograferingstyp år 2014 och har sedan dess varit otroligt populär hos mig, vilket jag förstår! Det är så fantastiskt roligt att få se hur just ditt barn reagerar
          på att få en HEL tårta ställd framför sig. Vissa älskar det, andra tycker det är läskigt och konstigt. En del slutar inte att äta, andra kladdar loss men vägrar att smaka.</p>
        <p>Ni får helt enkelt unika bilder som visar ert barns personlighet!</p>
      </div>
    </div>
    <div id="carousel-holder">
      <div class="container no-padding">
        <ImageCarousel duration="3" :images="[
          './cakesmash/carousel/1.jpg',
          './cakesmash/carousel/2.jpg',
          './cakesmash/carousel/3.jpg',
          './cakesmash/carousel/4.jpg',
          './cakesmash/carousel/44.jpg',
          './cakesmash/carousel/5.jpg',
          './cakesmash/carousel/6.jpg',
          './cakesmash/carousel/66.jpg',
          './cakesmash/carousel/7.jpg',
          './cakesmash/carousel/8.jpg',
          './cakesmash/carousel/9.jpg',
          './cakesmash/carousel/10.jpg',
          './cakesmash/carousel/11.jpg',
          './cakesmash/carousel/12.jpg',
        ]"/>
      </div>
    </div>
    <div id="second-paragraph">
      <div class="container">
        <p><strong>Första halvan</strong> av fotograferingen tar vi okladdiga lite mer klassiska ettårsbilder i en fin kalasoutfit, vi har också tid att använda mina små och unika plagg
        till barn om så önskas. Det går även bra att få till lite <strong>familjebilder</strong>.</p>
        <p>Sedan byter vi om på er lilla till en kladdvänlig outfit ni kan <strong>låna av mig</strong> om ni inte har något eget ni vill använda. Tårtan ställs fram och ert barn får upptäcka
        den precis som de <strong>själva vill</strong>, i sin egen takt och på sitt sätt.</p>
      </div>
    </div>
    <div id="image-section" class="container">
      <img src="@/assets/cakesmash/first.jpg"/>
      <img src="@/assets/cakesmash/second.jpg"/>
      <img src="@/assets/cakesmash/third.jpg"/>
    </div>
    <div id="video-section" class="container no-padding">
      <VideoPlayer id="sQX8R1aEw-4"/>
    </div>
    <Prices photography_price="1 500" :lines="[
      'Upp till <strong>1,5 h</strong> fotograferingstid',
      'En specialbeställd <strong>tårta</strong>',
      '&quot;Vanliga&quot; <strong>kladdfria</strong> bilder efter önskemål'
      ]" />
    <ContactForm section="Cake smash"/>
  </div>
</template>

<script>
  import ImageCarousel from "@/components/ImageCarousel";
  import VideoPlayer from "@/components/VideoPlayer";
  import Prices from "@/components/Prices";
  import ContactForm from "@/components/ContactForm";

  export default {
    name: "Cakesmash",
    components: {
      ImageCarousel,
      VideoPlayer,
      Prices,
      ContactForm
    },
    metaInfo() { return {
      title: 'Cake Smash - Studio Petite',
      meta: [{
        name: 'description',
        content: ''
      }]
    }}
  }
</script>

<style scoped lang="stylus">

  div#cakesmash

    h1
      margin: 0 auto

    h2
      font-size: 16px
      margin: 0 auto 30px

    div#first-paragraph
      padding: 20px 0

    div#carousel-holder
      max-width: 1200px
      margin: 0 auto
      padding: 20px 0

      div.container
        position: relative
        padding: 33.34% 0
        box-sizing: border-box

    div#second-paragraph
      background-color: #ece8e3
      padding: 20px 0

    div#image-section
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
      gap: 20px
      padding: 20px 0

      img
        max-width: 100%
</style>