<template>
  <div id="info-section">
    <div id="first-section" class="container no-padding">
      <img src="@/assets/info/first.jpg"/>
      <div class="text">
        <h1>Vem är det som gömmer sig bakom kameran?</h1>
        <p>Mitt namn är Jonna och jag är en barnfotograf i Tyresö/Stockholm. Studio Petite skapades då jag tog över en ny lokal som jag tillsammans med min man renoverat upp.
          Allt för att skapa en harmonisk och lugn känsla i studion som jag hoppas ni kommer trivas i under er fotografering!</p>
        <p>Jag startade mitt företag år 2012 direkt efter gymnasiet och fotade bröllop, åt företag samt barn/familj. Jag insåg ganska snabbt att det är barnfamiljer
          jag ska fotografera och riktade då in mig på barn och främst på bebisar 0-1 år. Jag hade min första fotostudio 2013 fram tills jag fick min första son.</p>
        <p>Efter min andra son investerade jag i en ny studio, jag kan äntligen jobba heltid igen efter mina föräldraledigheter och här är jag nu!</p>
      </div>
    </div>
    <div id="second-section" class="container no-padding">
      <div class="text">
        <p>Jag lever själv i småbarnslivet för tillfället då jag som sagt har två små vildingar. En snart femåring och en på två.</p>
        <p>Jag är otroligt barnkär vilket kanske är lätt att lista ut, får ofta höra att jag har ett lugn och att lite blygare barn
          accepterar mig lite lättare än vanligtvis. Jag själv var blyg som barn och jag tror att jag tack vare det vet hur det kan kännas och hur det ska hanteras. Tålamod är något jag också har mycket av.</p>
        <p> Jag är uppvuxen med massor av olika djur, bland annat hundar så det är något som också ligger mig varmt om hjärtat! Era fyrbenta vänner är mer än välkomna till era familjefotograferingar!</p>
      </div>
      <img src="@/assets/info/second.jpg"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Info",
    metaInfo() { return {
      title: 'Info - Studio Petite',
      meta: [{
        name: 'description',
        content: ''
      }]
    }}
  }
</script>

<style scoped lang="stylus">
  div#info-section
    padding: 30px 0 50px

    div.container
      display: flex

      h1
        margin: 20px auto 40px

      p
        font-size: 16px
        line-height: 24px
        margin: 20px 0

    div.text
      display: flex
      flex-direction: column
      justify-content: center
      padding: 0 40px

  div#first-section
    margin-bottom: 30px

    img
      width: 500px
      max-width: 100%

    div.text
      background-color: #e5e0d6
      margin-left: 40px

  div#second-section

    img
      width: 600px
      max-width: 100%

    div.text
      background-color: #dedcd7
      margin-right: 40px

  @media (max-width: 600px)
    div#info-section
      padding: 0

      div.container
        flex-direction: column

      div.text
        padding: 20px
        margin: 0

    div#first-section
      margin-bottom: 0

    div#second-section img
      order: -1

</style>