<template>
  <div class="carousel" v-touch:swipe.left="nextImage" v-touch:swipe.right="prevImage">
    <div class="arrow prev" v-on:click="prevImage"></div>
    <div class="arrow next" v-on:click="nextImage"></div>
    <div class="images">
      <img v-for="(image, index) in images" :key="image" :src="require.context('@/assets/', true, /.*/)(image)" :class="{active: activeIndex === index, prev: prevIndex === index}">
    </div>
    <div class="dots">
      <div class="dot" :class="{active: activeIndex === index}" v-for="index in images.keys()" :key="index" v-on:click="selectImage(index)"></div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue"
  import Vue2TouchEvents from "vue2-touch-events"
  Vue.use(Vue2TouchEvents)

  let activeIndex = 0, prevIndex = undefined;

  let timer;

  export default {
    name: "ImageCarousel",
    props: [
      "images",
      "duration"
    ],
    data() { return {
      activeIndex: activeIndex,
      prevIndex: prevIndex,
      timer: timer,
    }},
    methods: {
      selectImage: function(index) {
        this.prevIndex = this.activeIndex;
        this.activeIndex = index;

        this.loadTimeout();
      },
      prevImage: function() {
        this.selectImage((this.activeIndex !== 0 ? this.activeIndex : this.images.length) - 1);
      },
      nextImage: function() {
        this.selectImage((this.activeIndex + 1) % this.images.length);
      },
      loadTimeout: function() {
        if (this.duration > 0) {
          clearTimeout(this.timer);
          this.timer = setTimeout(this.nextImage, this.duration * 1000);
        }
      }
    },
    mounted() {
      this.loadTimeout();

      window.addEventListener('keydown', function(e) {
        if (e.code === "ArrowLeft")
          this.prevImage();
        else if (e.code === "ArrowRight")
          this.nextImage();
      }.bind(this));
    }
  }
</script>

<style scoped lang="stylus">
  @font-face
    font-family: 'squarespace-ui-font'
    src: url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.eot')
    src: url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.eot?#iefix') format('embedded-opentype'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.svg#squarespace-ui-font') format('svg'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.woff') format('woff'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.ttf') format('truetype')
    font-weight: normal
    font-style: normal

  @keyframes fadeIn
    0%
      opacity: 0
    100%
      opacity: 1

  @keyframes fadeOut
    0%
      opacity: 1
    100%
      opacity: 0

  div.carousel

    div.images

      img
        display: inline
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        min-width: 100%
        min-height: 100%
        max-width: 100%
        max-height: 100%
        object-fit: cover
        opacity: 0

        &.active
          z-index: 5
          opacity: 1
          animation: fadeIn 1s

        &.prev
          z-index: 4
          animation: fadeOut 1s

    div.dots
      position: absolute
      bottom: 20px
      left: 50%
      transform: translateX(-50%)
      display: flex
      z-index: 6


      @media (max-width: 600px)
        bottom: 10px

      div.dot
        height: 10px
        width: 10px
        border: 1px solid #fff
        border-radius: 10px

        @media (max-width: 600px)
          height: 7px
          width: 7px
          border-radius: 7px

        & + div.dot
          margin-left: 10px

        &.active
          background-color: rgba(255, 255, 255, 0.5)

    div.arrow
      position: absolute
      top: 0
      bottom: 0
      width: 10%
      z-index: 6
      font-family: squarespace-ui-font
      font-size: 20px
      color: #fff
      cursor: pointer
      display: flex
      justify-content: center
      align-items: center
      -webkit-tap-highlight-color: transparent

      @media (min-width: 600px)
        &:hover
          background-color: rgba(255, 255, 255, 0.1)

      &.prev
        left: 0

        &::before
          content: '\e02c'
          vertical-align: middle

      &.next
        right: 0

        &::before
          content: '\e02d'

</style>