<template>
  <div id="pregnant">
    <h3>Snart är ni fler i familjen</h3>
    <hr/>
    <div id="first-paragraph">
      <div class="container">
        <h1>Gravidfotografering</h1>
        <p>Stort grattis till er kommande familjemedlem! Detta är bara början på er livslånga resa som kommer att bli fylld av kärlek, ni kommer att förälska er i ert barn på ett sätt som ni inte kan föreställa er!</p>
        <p>Kanske älskar du att vara gravid eller så är du inte alls bekväm i det. Oavsett vad är det en speciell tid i ditt liv som är värt att fånga på bild!</p>
        <p>Jag var den som inte alls trivdes som gravid. Jag upplevde inte det så kallade "gravid-glowet" och kände mig allt annat än vacker. Vet du vad? Idag är jag ändå så tacksam över gravidbilder på mig.
          När jag ser tillbaka på bilderna så inser jag att jag var fin. Min kärlek och spänningen på vem som gömmer sig där inne lyste igenom. Det gör den för alla jag har framför min kamera!</p>
      </div>
    </div>
    <div id="carousel-holder">
      <div class="container no-padding">
        <ImageCarousel duration="3" :images="[
          './pregnant/carousel/1.jpg',
          './pregnant/carousel/2.jpg',
          './pregnant/carousel/3.jpg',
          './pregnant/carousel/4.jpg',
          './pregnant/carousel/5.jpg',
          './pregnant/carousel/6.jpg',
          './pregnant/carousel/7.jpg',
          './pregnant/carousel/8.jpg',
          './pregnant/carousel/9.jpg',
          './pregnant/carousel/10.jpg',
          './pregnant/carousel/11.jpg',
        ]"/>
      </div>
    </div>
    <div id="second-paragraph">
      <div class="container">
        <p>Vi skapar tillsammans vackra bilder på blivande mamman och magen, samt självklart med den andra blivande partnern och om det finns syskon. Mitt mål är att ni ska känna tacksamhet över dessa bilder i många år framöver!</p>
        <p>Ni väljer om ni vill komma till min studio eller fotas utomhus. I studion går det att ta lite mer avklädda bilder. Jag har klänningar och tyger som går att låna, dessa är i ljusa och neutrala färger. Alla klänningar på
        bilderna ni ser här finns att låna.</p>
      </div>
    </div>
    <div id="image-section" class="container">
      <img src="@/assets/pregnant/first.jpg"/>
      <img src="@/assets/pregnant/second.jpg"/>
      <img src="@/assets/pregnant/third.jpg"/>
    </div>
    <Prices photography_price="995" :lines="['Upp till <strong>1,5 h</strong> fotograferingstid']"/>
    <ContactForm section="Gravid"/>
  </div>
</template>

<script>
  import ImageCarousel from "@/components/ImageCarousel";
  import Prices from "@/components/Prices";
  import ContactForm from "@/components/ContactForm";

  export default {
    name: "Pregnant",
    components: {
      ImageCarousel,
      Prices,
      ContactForm
    },
    metaInfo() { return {
      title: 'Gravid - Studio Petite',
      meta: [{
        name: 'description',
        content: ''
      }]
    }}
  }
</script>

<style scoped lang="stylus">

  div#pregnant

    h1
      margin: 0 auto 30px

    div#first-paragraph
      padding: 20px 0

    div#carousel-holder
      max-width: 1200px
      margin: 0 auto
      padding: 20px 0

      div.container
        position: relative
        padding: 33.34% 0
        box-sizing: border-box

    div#second-paragraph
      background-color: #ece8e3
      padding: 20px 0

    div#image-section
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
      gap: 20px
      padding: 20px 0

      img
        max-width: 100%
</style>