<template>
  <div id="contact">
    <form id="contact-form" @submit.prevent="sendEmail">
      <VueRecaptcha sitekey="6LfOD3gaAAAAAIcCFug_sZ2ttOxiWeH-swhZbhZU" size="invisible" ref="recaptcha" :loadRecaptchaScript="true" @verify="verified"/>
      <div class="container" v-if="!isSent">
        <h3>{{title || 'Boka fotografering'}}</h3>
        <input type="text" name="first_name" placeholder="Förnamn" required/>
        <input type="text" name="last_name" placeholder="Efternamn" required/>
        <input type="email" name="email" placeholder="E-post" required/>
        <input type="text" name="phone" placeholder="Telefon"/>
        <input type="text" name="kids_age" placeholder="Barnens ålder" required v-if="section == 'Familj'"/>
        <input type="text" name="due_date" placeholder="BF-datum/födelsedatum" required v-if="section == 'Nyfödd'"/>
        <input type="text" name="pregnancy_week" placeholder="Nuvarande gravidvecka" required v-if="section == 'Gravid'"/>
        <input type="text" name="kid_birthday" placeholder="Barnets födelsedag" required v-if="section == 'Cake smash'"/>
        <select name="referrer" required>
          <option value="" disabled selected hidden>Hur hittade du mig?</option>
          <option value="Google">Google</option>
          <option value="Facebook">Facebook</option>
          <option value="Instagram">Instagram</option>
          <option value="Rekommendation">Rekommendation</option>
          <option value="Lokalt">Lokalt/studion</option>
          <option value="Annat">Annat</option>
        </select>
        <textarea rows="5" name="message" placeholder="Ditt meddelande" required></textarea>
        <input type="hidden" name="section" :value="section"/>
        <button type="submit">Skicka</button>
      </div>
      <div class="container" v-if="isSent">
        <p v-if="!errorOccured">Tack för att du kontaktade mig, jag kommer att höra av mig inom kort!<br />
          Om du inte hittar ett svar av mig inom 48 timmar, kolla i din skräppost eller kontakta mig igen.</p>
        <p v-if="errorOccured">Dessvärre gick någonting fel, vänligen försök igen lite senare.</p>
      </div>
    </form>
  </div>
</template>

<script>
  import emailjs from 'emailjs-com';
  import VueRecaptcha from 'vue-recaptcha';

  let isSent = false;
  let errorOccured = false;

  function defer() {
    let resolve;
    let promise = new Promise((res) => {
      resolve = res;
    });
    promise.resolve = resolve;
    return promise;
  }
  let isVerified = defer();

  export default {
    name: "ContactForm",
    props: [
      "section",
      "title"
    ],
    components: {
      VueRecaptcha
    },
    data() { return {
      isSent: isSent,
      errorOccured: errorOccured,
      isVerified: isVerified
    }},
    methods: {
      verified() {
        this.isVerified.resolve();
      },
      sendEmail(e) {
        this.$refs.recaptcha.execute();
        this.isVerified.then(() => {
          emailjs.sendForm('service_s30ax7m', 'template_gSel21w', e.target, 'user_fsmOup2fskkgKksDb1iGj')
            .then(() => {
              this.isSent = true;
            }, () => {
              this.isSent = true;
              this.errorOccured = true;
            });
          this.$gtag.event('contact', {
            event_category: 'engagement',
            event_label: 'Kontakt via ' + this.$props.section
          });
        });
      }
    }
  }
</script>

<style scoped lang="stylus">
  @import url('https://fonts.googleapis.com/css?family=Mukta+Malar')

  div#contact
    background-color: #eae2d1
    margin-top: 60px
    padding: 30px 0

    div.container
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 20px

      @media (max-width: 600px)
        grid-template-columns: repeat(1, 1fr)

    h3
      margin-top: 20px
      grid-column: 1/-1

    input, select, textarea, button
      font-family: 'Mukta Malar'
      font-size: 14px
      letter-spacing: 0.8px
      border: 0
      outline: none
      padding: 5px
      background-color: transparent

      &::placeholder
        color: #222
        text-transform: uppercase

      &:not(button)
        border-bottom: 1px solid #464646

    input, textarea
      max-width: calc(100% - 10px)
    select, button
      max-width: calc(100%)

    textarea
      grid-column: 1/-1

    select
      padding: 5px 0
      option
        color: #222
        text-transform: initial

      &:required:invalid
          text-transform: uppercase

    button
      padding: 12px 5px
      background-color: #d8ceb9
      text-transform: uppercase
      grid-column: 1/-1

      &:hover
        background-color: #cfc6b3

    p
      grid-column: 1/-1
      text-align: center
      font-size: 18px
      line-height: 30px

</style>