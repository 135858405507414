<template>
  <div id="start-page">
    <div id="carousel-holder">
      <ImageCarousel duration="3" :images="[
        './start/carousel/7.jpg',
        './start/carousel/4.jpg',
        './start/carousel/3.jpg',
        './start/carousel/2.jpg',
        './start/carousel/1.jpg',
        './start/carousel/5.jpg',
        './start/carousel/6.jpg',
      ]"/>
    </div>
    <h1>Barnfotograf och familjefotograf</h1>

    <h2>med fotostudio i Tyresö/Stockholm</h2>
    <hr/>
    <div class="container">
      <h3 class="narrow">Värdefulla stunder i ert liv</h3>
      <p>Välkommen till Studio Petite! Jag är en specialiserad barnfotograf som har inriktat mig främst på bebisar mellan 0-1 år men som också älskar att föreviga hela er familj eller graviditet.</p>
      <p>Gillar ni jordnära, ljusa färger och inte allt för mycket krusiduller så har ni hittat rätt! Här kommer ni att få mysiga bilder med en lite varmare ton och en lite enklare stil som kommer att funka i många år framöver
        till alla möjliga inredningstrender och hem. Jag ser framemot att skapa fina minnen med er! </p>
        <p>Vill ni veta mer om mig bakom Studio Petite? <a href="/info">Klicka här</a>.</p>
    </div>
    <div id="start-sections">
      <div class="container">
        <a href="/pregnant" class="section">
          <h2>Gravid</h2>
          <img src="@/assets/start/sections/pregnant.jpg"/>
        </a>
        <a href="/newborn" class="section">
          <h2>Nyfödd</h2>
          <img src="@/assets/start/sections/newborn.jpg"/>
        </a>
        <a href="/kid" class="section">
          <h2>Barn</h2>
          <img src="@/assets/start/sections/kid.jpg"/>
        </a>
        <h3>En investering för livet</h3>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageCarousel from "@/components/ImageCarousel";

  export default {
    name: "StartPage",
    components: {
      ImageCarousel
    },
    metaInfo() { return {
      title: 'Startsida - Studio Petite',
      meta: [{
        name: 'description',
        content: ''
      }]
    }}
  }
</script>

<style scoped lang="stylus">

  div#start-page
    padding-top: calc(100vh - 100px)

    div#start-sections
      margin-top: 50px
      background-color: #eeeae8

      div.container
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
        gap: 20px

      a
        color: #222
        text-decoration: none

        img
          max-width: calc(100% - 2px)

        &:hover
          opacity: 0.7

      h3
        grid-column: 1/-1
        margin: 10px auto 30px
</style>