<template>
  <div id="contact-section">
    <div class="container no-padding">
      <div id="contact-container">
        <div id="address">
          <h3>Hitta hit</h3>
          <p>Studio Petite</p>
          <p>Strandtorget 11</p>
          <p>135 63 Tyresö</p>
        </div>
        <ContactForm section="Kontaktsida" title="Kontakta mig"/>
      </div>
      <GmapMap
          :center="{lat:59.2421564, lng:18.2840422}"
          :zoom="18"
          map-type-id="terrain"
      >
        <GmapMarker
            :position="google && new google.maps.LatLng(59.2421564,18.2840422)"
            :clickable="true"
            :draggable="true"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
  import ContactForm from "@/components/ContactForm";
  import Vue from 'vue'
  import * as VueGoogleMaps from 'vue2-google-maps'
  import {gmapApi} from 'vue2-google-maps'

  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCzFy79lHovtvn_Ynz6_haUpB-A7s4FREg'
    }
  });

  export default {
    name: "Contact",
    components: {
      ContactForm
    },
    metaInfo() { return {
      title: 'Kontakt - Studio Petite',
      meta: [{
        name: 'description',
        content: ''
      }]
    }},
    computed: {
      google: gmapApi
    }
  }
</script>

<style lang="stylus">

  div#contact-section
    padding: 10px 20px

    div.container
      display: flex
      align-items: stretch

    div#contact-container
      display: flex
      flex-direction: column
      width: 50%
      box-sizing: border-box
      margin: 0
      padding: 0 20px 0 0

      div#contact
        background-color: #fff
        padding-bottom: 0
        margin-top: 0

      div.container
        grid-template-columns: repeat(1, 1fr)

    div.vue-map-container
      flex-grow: 1
      margin-top: 20px

  @media screen and (max-width 600px)
    div#contact-section
      padding: 10px 0 0

      div.container
        flex-direction: column

      div#contact-container
        width: 100%
        padding: 0
        flex-direction: column-reverse

        > h3
          margin-top: 20px

      div.vue-map-container
        height: 500px

</style>