<template>
  <div id="kid" class="container">
    <a href="/newborn">
      <h2>Nyfödd</h2>
      <img src="@/assets/kid/newborn.jpg"/>
    </a>
    <a href="/cakesmash">
      <h2>Cake smash</h2>
      <img src="@/assets/kid/cakesmash.jpg"/>
    </a>
    <a href="/kid-family">
      <h2>Barn & familj</h2>
      <img src="@/assets/kid/kid-family.jpg"/>
    </a>
  </div>
</template>

<script>
  export default {
    name: "Kid",
    metaInfo() { return {
      title: 'Barn - Studio Petite',
      meta: [{
        name: 'description',
        content: ''
      }]
    }}
  }
</script>

<style scoped lang="stylus">

  div#kid
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
    gap: 30px

    @media (min-width: 600px)
      margin-top: 20vh

    a
      color: #222
      text-decoration: none

      img
        max-width: calc(100% - 2px)

      &:hover
        opacity: 0.7

</style>