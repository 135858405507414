<template>
  <div id="app">
    <Navigation/>
    <div id="content">
      <StartPage v-if="route === 'start'"/>
      <Pregnant v-if="route === 'pregnant'"/>
      <Kid v-if="route === 'kid'"/>
      <Newborn v-if="route === 'newborn'"/>
      <KidFamily v-if="route === 'kid-family'"/>
      <Cakesmash v-if="route === 'cakesmash'"/>
      <Info v-if="route === 'info'"/>
      <Contact v-if="route === 'contact'"/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Vue from "vue";
import VueMeta from "vue-meta";
import VueGtag from 'vue-gtag';

import Navigation from '@/components/Navigation.vue'
import StartPage from "@/components/StartPage";
import Pregnant from "@/components/Pregnant";
import Kid from "@/components/Kid";
import Newborn from "@/components/Newborn";
import Cakesmash from "@/components/Cakesmash";
import KidFamily from "@/components/KidFamily";
import Info from "@/components/Info";
import Contact from "@/components/Contact";
import Footer from "@/components/Footer";

Vue.use(VueMeta);
Vue.use(VueGtag, {
  config: { id: "G-4FSBEF7Z7R" }
});

export default {
  name: 'App',
  components: {
    Navigation,
    StartPage,
    Pregnant,
    Kid,
    Newborn,
    Cakesmash,
    KidFamily,
    Info,
    Contact,
    Footer
  },
  data() { return {
    route: window.location.pathname.substring(1) || 'start'
  }}
}
</script>

<style lang="stylus">
  @import url('https://fonts.googleapis.com/css?family=Quicksand|Raleway|Dancing+Script')

  html
    margin: 0
    padding: 0
    height: 100vh

  body
    margin: 0
    min-height: 100vh

  #app
    display: flex
    flex-direction: column
    align-items: stretch
    width: 100%
    min-height: 100vh
    box-sizing: border-box
    font-family: Quicksand
    font-weight: 300
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

  #content
    flex-grow: 1

  .container
    max-width: 1200px
    margin: 0 auto

    > p
      margin: 20px auto
      max-width: 900px

    @media (max-width: 1200px)
      &:not(.no-padding)
        padding: 0 10px

  h1
    margin: 30px auto 20px
    max-width: 95%
    font-family: 'Dancing Script'
    font-weight: 400
    text-align: center
    font-size:30px
    letter-spacing: 1px

  h2
    margin: 20px auto 20px
    max-width: 95%
    font-family: 'Dancing Script'
    font-weight: 400
    text-align: center
    font-size: 20px
    letter-spacing: 1px

  h3
    margin: 70px auto 30px
    max-width: 95%
    font-family: Raleway
    font-weight: 500
    text-align: center
    text-transform: uppercase
    font-size: 13px
    color: #333
    letter-spacing: 6px
    line-height: 24px

    &.narrow
      letter-spacing: 4px

  p
    line-height: 20px
    font-size: 14px
    text-align: center
    color: #333

  a
    color: #666
    text-decoration: none

    &:hover
      color: #333

  hr
    width: 700px
    max-width: 95%
    margin: 30px auto 15px
    border: 0
    border-top: 1px solid #bfbfbf

  .grecaptcha-badge
    visibility: hidden

</style>
