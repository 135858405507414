<template>
  <nav>
    <div class="container">
      <ul class="menu">
        <li><a href="/">Hem</a></li>
        <li><a href="/pregnant">Gravid</a></li>
        <li class="submenu">
          <a href="/kid">Barn</a>
          <ul>
            <li><a href="/newborn">Nyfödd</a></li>
            <li><a href="/cakesmash">Cake smash</a></li>
            <li><a href="/kid-family">Barn & familj</a></li>
          </ul>
        </li>
      </ul>
      <a id="logo" href="/"><img src="@/assets/navigation/logo.png"></a>
      <ul class="menu">
        <li><a href="/info">Info</a></li>
        <li><a href="/contact">Kontakt</a></li>
        <li>
          <a target="_blank" href="https://instagram.com/fotografjonna"><img src="@/assets/navigation/instagram.png"/></a>
          <a target="_blank" href="https://facebook.com/studiopetite.se"><img src="@/assets/navigation/facebook.png"/></a>
        </li>
      </ul>
      <img id="menu_button" src="@/assets/navigation/menu_button.png">
      <ul id="mobile_menu">
        <li class="close"><img id="close_button" src="@/assets/navigation/close_button.png"></li>
        <li><a href="/">Hem</a></li>
        <li><a href="/pregnant">Gravid</a></li>
        <li><a href="/newborn">Nyfödd</a></li>
        <li><a href="/cakesmash">Cake smash</a></li>
        <li><a href="/kid-family">Barn & familj</a></li>
        <li><a href="/info">Info</a></li>
        <li><a href="/contact">Kontakt</a></li>
        <li class="icons">
          <a target="_blank" href="https://instagram.com/fotografjonna"><img src="@/assets/navigation/instagram.png"/></a>
          <a target="_blank" href="https://facebook.com/studiopetite.se"><img src="@/assets/navigation/facebook.png"/></a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  import $ from 'jquery'

  export default {
    name: "Navigation",
    mounted() {
      $('#menu_button').on('click', function() {
        $('#mobile_menu').fadeIn();
      });
      $('#close_button').on('click', function() {
        $('#mobile_menu').fadeOut();
      });
    }
  }
</script>

<style scoped lang="stylus">
  @import url('https://fonts.googleapis.com/css?family=Spinnaker')

  nav
    padding: 20px 0
    background-color: rgba(255, 255, 255, 0.4)
    position: relative
    z-index: 100

    div.container
      display: flex
      justify-content: space-between
      align-items: center


    a#logo img
      width: 250px

    ul.menu
      display: flex
      width: 100%
      justify-content: space-evenly
      flex-wrap: nowrap
      margin: 0
      padding: 0

      li
        list-style: none

        &.submenu:hover ul
          display: block


      ul
        display: none
        position: absolute
        top: calc(100% - 70px)
        margin-left: -20px
        padding: 30px 0 0

        li
          background-color: rgba(255, 255, 255, 0.4)
          padding: 10px 20px

          &:first-child
            background-color: transparent

    ul li a
      text-decoration: none
      font-family: 'Spinnaker'
      font-size: 14px
      letter-spacing: 0.7px
      text-transform: uppercase
      white-space: nowrap
      color: #684d4a
      vertical-align: middle

      &:hover
        color: #000000

      img
        width: 26px
        vertical-align: middle

      & + a
        margin-left: 10px

    ul#mobile_menu
      display: none

  @media screen and (min-width 900px)
    a#logo
      margin: 0 10px
    img#menu_button
      display: none

  @media screen and (max-width 900px)
    ul.menu
      display: none !important

    ul#mobile_menu
      position: fixed
      background-color: #e4e2dd
      top: 0
      left: 0
      right: 0
      bottom: 0
      padding: 0
      margin: 0

      li
        border-bottom: 1px solid #6a6a6a
        margin: 0 30px
        list-style: none

        &.icons
          padding-top: 40px
          text-align: center

          a
            display: inline

            img
              width: initial

        &.close
          border-bottom-width: 0
          margin-top: 55px
          text-align: right

          img#close_button
            width:24px

        &:last-child
          border-bottom-width: 0

        a
          display: block
          padding: 20px 0
          font-size: 12px4
          text-align: center

    a#logo
      margin-left: 10px
      width: 100%
      text-align: center

      img

        width: 100%

    img#menu_button
      width: 32px
      height: 16px
      margin-right: 20px
      opacity: 0.5
</style>