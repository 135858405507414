<template>
  <div id="prices">
    <div class="container">
      <h3>En investering för livet</h3>
      <div class="container">
        <p>Bilder är något som ökar i värde för v a r j e år, ju äldre ditt barn blir desto mer tacksam blir du för bilderna ni har investerat i.
          Något ni alltid kommer att hålla varmt om hjärtat och aldrig ångra. Ett sätt att stanna tiden för ett ögonblick, tiden som går alldeles för fort.</p>
      </div>
    </div>
    <div id="pricelist" class="container">
      <div id="photography-price">
        <p class="title">Fotograferingspris</p>
        <h3 :class="{original_price: !!discounted_photography_price}">{{photography_price}} kr</h3>
        <h3 v-if="discounted_photography_price" class="discounted_price">
          <span>Just nu</span>
          {{discounted_photography_price}} kr
          <span v-if="discounted_period">(Gäller fotografering {{discounted_period}})</span>
        </h3>
        <p class="disclaimer">(Bilder köps separat)</p>
        <p>Detta ingår:</p>
        <ul>
          <li v-for="line in lines" v-bind:key="line" v-html="line"></li>
          <li>Lån av <strong>rekvisita</strong> och outfits</li>
          <li>Kostnadsfri <strong>ombokning</strong> vid sjukdom</li>
          <li><strong>Personligt urvalsgalleri</strong> online där ni välja ut era favoritbilder</li>
          <li>✰ Fri tillgång till <strong>kaffe</strong> ✰</li>
        </ul>
      </div>
      <div id="package-medium">
        <h3>Bildpaket MELLAN</h3>
        <p>5 st digitalt högupplösta bilder</p>
        <p class="disclaimer">Levereras i både färg och svartvitt</p>
        <h3>2 500 kr</h3>
      </div>
      <div id="package-large">
        <h3>Bildpaket STOR</h3>
        <p>10 st digitalt högupplösta bilder</p>
        <p class="disclaimer">Levereras i både färg och svartvitt</p>
        <h3>4 500 kr</h3>
      </div>
      <div id="package-premium">
        <h3>Bildpaket PREMIUM</h3>
        <p>ALLA bilder i ert urvalsgalleri digitalt högupplösta, mellan 20-40 st</p>
        <p class="disclaimer">Levereras i både färg och svartvitt</p>
        <h3>9 500 kr</h3>
      </div>
      <p id="editing-style">Bilderna ni köper är noggrant bearbetade efter min redigeringsstil, en mysig och lite varmare ton.</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Prices",
    props: [
      "photography_price",
      "discounted_photography_price",
      "discounted_period",
      "lines"
    ]
  }
</script>

<style scoped lang="stylus">
  div#prices
    h3
      margin: 20px auto

    div#pricelist
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
      gap: 40px
      max-width: 800px
      margin-top: 40px

      div
        padding: 20px

      div#photography-price
        grid-column: 1/-1
        background-color: #dad1bd

        h3
          font-size: 30px
          margin-bottom: 10px

          &.original_price
            text-decoration: line-through
            color: #666

          &.discounted_price
            margin: 5px auto 30px
            color: #aa5f5f

            span
              display: block
              font-size: 14px

        p.title
          font-size:20px

      div#package-medium, div#package-large
        background-color: #e9e3d6

      div#package-premium
        grid-column: 1/-1
        background-color: #e9e3d6

      h3
        font-family: arial
        font-size: 20px
        letter-spacing: 1px
        text-transform: initial
        color: #222

      ul
        display: table
        margin: 20px auto
        padding: 0
        list-style: none
        font-size: 14px

        li:before
          content: "-"
          margin-right: 7px

      p#editing-style
        margin: 0
        grid-column: 1/-1
        font-style: italic
        font-size:14px

      p.disclaimer
        margin-top: -10px
        font-style: italic
</style>