<template>
  <div id="newborn">
    <h3 class="narrow">En tid som är alldeles speciell</h3>
    <hr/>
    <div id="first-paragraph">
      <div class="container">
        <h2>Nyföddfotografering</h2>
        <p>En alldeles speciell fotografering som sker när ert barn är mellan 1-2 veckor gammal. Ni lär fortfarande känna ert nya tillskott,
          kanske har ni fullt upp med att få tillräckligt med sömn på nätterna, få igång amning och allt som hör till den första tiden.</p>
        <p>Ni kanske därför inte kommer minnas lika tydligt hur er bebis såg ut med hur mörkt håret var eller hur små fötterna faktiskt var.
          Därför är denna fotografering så speciell, jag förevigar detta åt er så att ni kan sitta och kolla tillbaka på dessa bilder
          när ni har kommit in i er föräldraroll fullt ut och har tiden till att reflektera över detaljerna lite mer.</p>
      </div>
    </div>
    <div id="carousel-holder">
      <div class="container no-padding">
        <ImageCarousel duration="3" :images="[
          './newborn/carousel/1.jpg',
          './newborn/carousel/11.jpg',
          './newborn/carousel/2.jpg',
          './newborn/carousel/3.jpg',
          './newborn/carousel/4.jpg',
          './newborn/carousel/5.jpg',
          './newborn/carousel/6.jpg',
          './newborn/carousel/7.jpg',
          './newborn/carousel/8.jpg',
          './newborn/carousel/9.jpg',
          './newborn/carousel/10.jpg',
        ]"/>
      </div>
    </div>
    <div id="second-paragraph">
      <div class="container">
        <p>Det finns gott om tid att ha matpaus och tröstpaus, ingen stress! Er bebis är min chef för dagen. Det är en mysig fotografering
          där vi har tiden för en sovandes eller vaken bebis, tillsammans med er och enskilda bilder. </p>
          <p> Vill er bebis sova så poserar jag de små händerna och fötterna, jag följer ofta med er bebis rörelser och rättar exempelvis till små fingrar därefter.
          Säkerheten är min största prio och jag skulle aldrig lägga er bebis i en pose som inte är säker. Mellan 5-14 dagar så är de små fortfarande mjuka i kroppen och gillar att ligga ihopkurade, det är då vi får till de fina sovande bilderna.</p>
        <p>Hör av er till mig i god tid, medan er bebis fortfarande är i magen. Ni bokas in på ert BF och sedan så hörs vi av när er lilla har kommit för att hitta vår fototid tillsammans.</p>
        <p>Låter detta intressant? Det enda ni behöver göra är att ta er till min studio, hur trötta ni än är. Mys ner er i soffan och vila medan bebis fotas för enskilda bilder &#9825;</p>
        <p class="bottom"><span>PS</span>Har ni redan hunnit föda och bebis är mellan 0-7 dagar fortfarande? Det är inte alltid lätt att hålla koll på allt och hinna med, ni är självklart välkomna med er förfrågan ändå. Ibland lyckas jag lösa en tid med kort varsel till er!</p>
      </div>
    </div>
    <div id="image-section" class="container">
      <img src="@/assets/newborn/first.jpg"/>
      <img src="@/assets/newborn/second.jpg"/>
      <img src="@/assets/newborn/third.jpg"/>
    </div>
    <div id="video-section" class="container no-padding">
      <VideoPlayer id="yzg_npo_9Zc"/>
    </div>
    <Prices photography_price="1 500" :lines="['Tid <strong>utan</strong> stress, runt 2 h fotograferingstid']"/>
    <ContactForm section="Nyfödd"/>
  </div>
</template>

<script>
  import ImageCarousel from "@/components/ImageCarousel";
  import VideoPlayer from "@/components/VideoPlayer";
  import Prices from "@/components/Prices";
  import ContactForm from "@/components/ContactForm";

  export default {
    name: "Newborn",
    components: {
      ImageCarousel,
      VideoPlayer,
      Prices,
      ContactForm
    },
    metaInfo() { return {
      title: 'Nyfödd - Studio Petite',
      meta: [{
        name: 'description',
        content: ''
      }]
    }}
  }
</script>

<style scoped lang="stylus">

  div#newborn

    div#first-paragraph
      padding: 20px 0

    div#carousel-holder
      max-width: 1200px
      margin: 0 auto
      padding: 20px 0

      div.container
        position: relative
        padding: 33.34% 0
        box-sizing: border-box

    div#second-paragraph
      background-color: #ece8e3
      padding: 20px 0

    div#image-section
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
      gap: 20px
      padding: 20px 0

      img
        max-width: 100%

    p.bottom
      margin-top: 40px
      font-style: italic
      font-size: 11px

      span
        display: block
        font-weight: bold
</style>